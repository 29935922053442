import { Button, Typography } from "@hero/krypton"
import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import styled from "styled-components"
import { Card } from "../../../../00_shared/components/Card"
import { FlexContainer } from "../../../../00_shared/components/Flex"
import { useDashboardTranslation } from "../../../../01_technical/translations"
import CardIcon from "../icons/card-physical-give.png"

const CloseButton = styled.button`
  background: none;
  border: none;
  color: ${({ theme }) => theme.colors.grey.$500};
  font-size: 1.25rem;
  cursor: pointer;
  position: absolute;
  top: 0.75rem;
  right: 0.75rem;
`

export const AdviceToDownloadAppCard = ({ onClose }: { onClose?: () => void }) => {
  const { t } = useDashboardTranslation()
  const [isVisible, setIsVisible] = useState(true)

  useEffect(() => {
    const isAdviceDismissed = localStorage.getItem("issuing_advice_to_download_app_dismissed")
    if (isAdviceDismissed) {
      setIsVisible(false)
    }
  }, [])

  const handleClose = () => {
    localStorage.setItem("issuing_advice_to_download_app_dismissed", "true")
    setIsVisible(false)
    onClose && onClose()
  }

  if (!isVisible) return null

  return (
    <Card style={{ position: "relative", padding: "1rem", height: "6.5rem" }}>
      <FlexContainer $gap="1rem">
        <img src={CardIcon} width={55} alt="Download the app" />
        <FlexContainer $direction="column" $gap=".75rem">
          <Typography $variant="body-4-medium">{t("issuing.adviceToDownloadApp.title")}</Typography>
          <FlexContainer>
            <Button as={Link} to="https://www.heropay.eu/download-app">
              {t("issuing.adviceToDownloadApp.downloadButton")}
            </Button>
          </FlexContainer>
        </FlexContainer>
      </FlexContainer>
      <CloseButton onClick={handleClose} aria-label="Close">
        &times;
      </CloseButton>
    </Card>
  )
}
