import { FieldInput, Typography, theme } from "@hero/krypton"
import { useNavigate } from "react-router-dom"
import styled from "styled-components"
import { Card, CardContent, CardHeader, CardTitle } from "../../../../00_shared/components/Card"
import { Dialog, DialogContent } from "../../../../00_shared/components/Dialog"
import useMediaQuery from "../../../../00_shared/hooks/useMediaQuery.hook"
import { useDashboardTranslation } from "../../../../01_technical/translations"

const CardContainer = styled(Card)`
  @media (max-width: 768px) {
    padding: 0;
    border: none !important;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`

const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const FieldLabel = styled(Typography).attrs(() => ({
  $variant: "body-4-medium",
}))`
  color: ${theme.colors.grey.$600};
`

// const CardFooterContainer = styled(CardFooter)`
//   @media (max-width: 768px) {
//     flex-direction: column;
//     align-items: stretch;
//   }
// `

// const DeleteButtonIcon = styled(DeleteIcon)`
//   width: 1rem;
// `

interface SettingsCardProps {
  isMobile?: boolean
  header: string
  accountName: string
  // deleteButtonText: string
  // handleDeleteAccount: () => void
}

const SettingsCard = ({ isMobile, header, accountName }: SettingsCardProps) => (
  <CardContainer>
    <FlexContainer>
      <CardHeader>
        <CardTitle size={isMobile ? "title-3-semibold" : "body-2-semibold"}>{header}</CardTitle>
      </CardHeader>
      <CardContent>
        <FieldLabel>{accountName}</FieldLabel>
        <FieldInput defaultValue="Compte principal" $fullWidth disabled type="text" />
      </CardContent>
    </FlexContainer>
    {/* <CardFooterContainer>
      <Button
        size="medium"
        centerIcon={DeleteButtonIcon}
        isLoading={false}
        $variant="danger"
        onClick={handleDeleteAccount}
      >
        {deleteButtonText}
      </Button>
    </CardFooterContainer> */}
  </CardContainer>
)

export const AccountDetailsSettings = () => {
  const { t } = useDashboardTranslation()
  const isMobile = useMediaQuery("(max-width: 768px)")
  const navigate = useNavigate()

  // const handleDeleteAccount = () => {
  //   alert("Account deleted")
  // }

  const preventAutoFocus = (event: Event) => {
    event.preventDefault()
  }

  const handleCloseOverlay = () => {
    navigate("..")
  }

  return (
    <>
      {!isMobile && (
        <SettingsCard
          header={t("accounts.details.settings.header")}
          accountName={t("accounts.details.settings.accountName.label")}
          // deleteButtonText={t("accounts.details.settings.deleteAccount")}
          // handleDeleteAccount={handleDeleteAccount}
        />
      )}
      {isMobile && (
        <Dialog open={true} onOpenChange={handleCloseOverlay}>
          <DialogContent onOpenAutoFocus={preventAutoFocus} $disableAnimations={true} width="100%" height="100%">
            <SettingsCard
              isMobile
              header={t("accounts.details.settings.header")}
              accountName={t("accounts.details.settings.accountName.label")}
              // deleteButtonText={t("accounts.details.settings.deleteAccount")}
              // handleDeleteAccount={handleDeleteAccount}
            />
          </DialogContent>
        </Dialog>
      )}
    </>
  )
}
