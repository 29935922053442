import { Button, Typography } from "@hero/krypton"
import { useNavigate } from "react-router-dom"
import { Card } from "../../../../../00_shared/components/Card"
import { FlexContainer } from "../../../../../00_shared/components/Flex"
import { toEuros } from "../../../../../00_shared/utils/currency.converter"
import { useCommonTranslation } from "../../../../../01_technical/translations"
import { LedgerMovementFlow } from "../../../00_shared/business"
import { useBusinessAccountContext } from "../../../businessAccount.context"
import TransferSentIcon from "../../00_shared/icons/transfer-sent-icon.png"
import { TransferType, useCreateTransferContext } from "../CreateTransferContext"

export const TransferSentScreen = ({ transactionId, recipient }: { transactionId: string; recipient: string }) => {
  const navigate = useNavigate()
  const { t } = useCommonTranslation()
  const { state, clearState } = useCreateTransferContext()
  const { navigateToCreateTransfer } = useBusinessAccountContext()

  const amount = toEuros(state.amount)
  const message =
    state.selectedTransferType === TransferType.BENEFICIARY
      ? `${recipient} ${t("transfer.beneficiary.confirmed.subTitle.one")} ${amount} ${t("transfer.beneficiary.confirmed.subTitle.two")}`
      : `${t("transfer.accountToAccount.confirmed.subTitle.one")} ${recipient} ${t("transfer.accountToAccount.confirmed.subTitle.two")}`

  const handleClickNewTransfer = () => {
    clearState()
    navigateToCreateTransfer()
  }

  const handleClickCheckTransfer = () => {
    navigate(`/transfers/transactions/${transactionId}`, {
      state: { flow: LedgerMovementFlow.BUSINESS_ACCOUNT_EXTERNAL_PAYOUT, ledgerMovementId: "", transactionId },
    })
  }

  return (
    <Card $variant="plain" style={{ paddingTop: "2rem" }}>
      <FlexContainer $direction="column" $justify="center" $align="center" $gap="2rem">
        <img src={TransferSentIcon} width={80} alt="Virtuelle card" />
        <FlexContainer $direction="column" $gap=".5rem" $align="center" $justify="center">
          <Typography $variant="title-1-bold" $color="grey.$600">
            {t("transfer.confirmed.title")}
          </Typography>
          <Typography $variant="body-3-regular" $color="grey.$500" style={{ maxWidth: "60%", textAlign: "center" }}>
            {message}
          </Typography>
        </FlexContainer>
        <FlexContainer $gap=".5rem" $direction="column" style={{ width: "100%" }}>
          <Button $fullWidth size="medium" onClick={handleClickNewTransfer}>
            {t("transfer.confirmed.button.newTransfer")}
          </Button>
          <Button $fullWidth $variant="tertiary" size="medium" onClick={handleClickCheckTransfer}>
            {t("transfer.confirmed.button.checkTransfer")}
          </Button>
        </FlexContainer>
      </FlexContainer>
    </Card>
  )
}
